import * as React from 'react';

import { HeroNav, Layout, SEO } from '../components';
import { CTA, FirstSection, HeroImage } from '../components/covid-19';

const COVIDPage = () => (
  <Layout
    formName="covid-page-bottom-form"
    formTitle="Buying or hiring? Contact us today"
    hideForm
  >
    <SEO
      title="COVID-19 Update - Your Health Comes First | Frontline Removals"
      description="During these uncertain times, we would like to let all of our valued customers know that we are still in operation, as we are classed as an essential service."
    />
    <div className="px-6 py-4">
      <HeroNav />
    </div>
    <FirstSection />
  </Layout>
);

export default COVIDPage;
