import * as React from 'react';

export function COVID19FirstSection() {
  return (
    <article className="max-w-6xl px-6 py-4 mx-auto mt-20 ">
      <h1 className="text-5xl font-semibold leading-none uppercase heading font-display">
        <span className="inline-block">Managing Infection </span>{' '}
        <span className="inline-block text-brand-orange">
          control IN our WORKPLACE
        </span>
      </h1>

      <div className="mt-6 rich-text">
        <p>
          With an increasing focus on infection control in the workplace, it is
          important that as a company we are aware of our employment health and
          safety responsibilities and the responsibilities we must have to our
          clients.
        </p>
        <p>
          Workplace Vaccination Obligations Currently, there are no legislative
          or regulatory requirements for an employer to mandate vaccinations in
          the workplace in most circumstances. However, State and Territory
          regulations may require employees undertaking duties in specific
          industries to receive specific up-to-date vaccinations.
        </p>
        <p>
          Employers have a health and safety duty to, as far as reasonably
          practicable, reduce the risk of a hazard occurring in the workplace.
          Therefore, employers must consider what control measures can be
          implemented to reduce the likelihood of the spread of infectious
          diseases (eg COVID-19, Influenza, Hepatitis) in the workplace. This
          may include implementing an immunisation program encouraging, or in
          some circumstances requiring, employees to get vaccinated.
        </p>
        <p>
          Encouraging our workers to receive vaccinations, delivered through an
          immunisation program, may be an effective control measure to reduce
          the likelihood of infection and its spread throughout the workplace,
          However, other control measures are still required to adequately meet
          our health and safety obligations.
        </p>
        <p>
          Currently we are testing our employees every 3 days and Control
          measures such as providing Personal Protective Equipment (PPE) and
          practicing hand hygiene. As per our outcome of our risk assessment.
        </p>

        <p>
          We also Keep records of employees who have been tested against
          COVID-19 and their results and all record keeping of our vaccinated
          employees against diseases. Along with other control measures
          implemented to reduce the risk of infection spread, subject to privacy
          obligations.
        </p>

        <p>
          Directing an employee to get a vaccinate is a grey area and depends on
          many factors. It is subject to change with evolving government
          guidance and Fair Work Commission cases in this area. Generally, you
          can give ‘lawful and reasonable’ directions to employees, which we
          must follow.
        </p>

        <p className="italic">
          If you have any further questions, Please Call our Team on{' '}
          <a href="tel:1300 652738" className="font-bold">
            1300 652738
          </a>{' '}
          or email on{' '}
          <a href="mailto:info@frontlineremovals.com.au" className="font-bold">
            info@frontlineremovals.com.au
          </a>
        </p>
      </div>
    </article>
  );
}
